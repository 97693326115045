import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Image,
  Nav,
  Row,
  Form,
  Dropdown,
  Accordion,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// import "../../../../Assets/css/MegaMenu.css";
import logoImage from "../../../../Assets/images/black-logo.png";
import Login from "../../../../Components/Login";
import { useHistory } from "react-router-dom";
import { Drawer } from "@mui/material";
import menuImages from "../../../../Assets/images/menu.png";
import {
  Email_details,
  client_name,
  contact_number,
} from "../../../../Helpers/request";
import { isMobile, isTablet } from "react-device-detect";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { BsFillChatFill, BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail, IoMdContact } from "react-icons/io";
import { img_url } from "../../../../Helpers/request";
// import HeaderStyle from "../Assets/megamenu.module.css";
import {
  currencyCode,
  currencyFlag,
  currencyIcon,
  loginDrawer,
} from "../../../../Reducer/homeReducer";
import { MdOutlineShoppingBag } from "react-icons/md";
import { BiHeart } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import Heart1 from "../../../../Assets/images/heart1.png";
import Heart2 from "../../../../Assets/images/heart2.gif";
import Cart1 from "../../../../Assets/images/bag1.png";
import Cart2 from "../../../../Assets/images/bag2.gif";
import Magnify0 from "../../../../Assets/images/search1.png";
import Magnify1 from "../../../../Assets/images/magnify2.gif";
import { MdAccountCircle } from 'react-icons/md';
import ComparePng from '../../../../Assets/images/compare.png';
import CompareGif from '../../../../Assets/images/compare.gif';
import SearchIcon from "@mui/icons-material/Search";
import { FaEnvelope, FaPhone, FaUser } from "react-icons/fa";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightRegular,
//   },
// }));

const MegaMenu = () => {
  const contact_number_link = contact_number.link;
  const Email_details_link = Email_details.link;
  const dispatch = useDispatch();
  const history = useHistory();
  const [setDesktopSearch, setShowDesktopSearch] = useState(false);
  const [submenu, setSubmenu] = useState([]);
  const [show, setShow] = useState(false);
  const [topHeader, setTopHeader] = useState(true);
  const [left, setLeft] = useState(false);
  const [hover, setHover] = useState(0);
  const [search_text, setSearch_text] = useState("");
  const [hoverindex, setHoverindex] = useState(false);
  const [search_show, setSearch_show] = useState(false);
  const [wishover, setWishover] = useState(false);
  const [carthover, setCarthover] = useState(false);
  const [searchhover, setSearchhover] = useState(false);
  const [compare, setCompare] = useState(false);
  const menudata = useSelector((state) => state.persistedReducer.home.menu);
  const currencycode = useSelector((state) => state.persistedReducer.home.currencycode);
  const currencyflag = useSelector((state) => state.persistedReducer.home.currencyflag);
  const currencydata = useSelector((state) => state.persistedReducer.home.currency);
  const user = useSelector(
    (state) => state.persistedReducer.home.userData
  );
  useEffect(() => {
    document.body.className = window.location.pathname;
    return () => {
      document.body.className = "";
    };
  }, [window.location.pathname]);

  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);

  const cartlength = useSelector(
    (state) => state.persistedReducer.cart.cartlength
  );
  const wishlistlength = useSelector(
    (state) => state.persistedReducer.wish.whishlistlength
  );
  const toggleDrawer = (open) => {
    setLeft(open);
  };

  useEffect(() => {
    // googleTranslateElementInit()
    const handleScroll = () => {
      // console.log(window.scrollY);
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 34);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,es,fr,de,iw,ja,zh-CN,sv,tr,ar,hi",
        gaTrack: true,
      },
      "google_translate_element"
    );
  };

  const currencyHandle = async (code, img, icon) => {
    await dispatch(currencyIcon(icon));
    await dispatch(currencyCode(code));
    await dispatch(currencyFlag(img));
    localStorage.removeItem("bw-fancycolordata");
    localStorage.removeItem("bw-gemstonedata");
    localStorage.removeItem("bw-diamonddata");
    localStorage.removeItem("bw-settingdata");
    sessionStorage.removeItem("bw-listfilter");
    sessionStorage.removeItem("bw_f_filter");
    sessionStorage.removeItem("bw_s_filter");
    sessionStorage.removeItem("bw_d_filter");
    sessionStorage.removeItem("bw_g_filter");
    sessionStorage.removeItem("bw-list-category");
    if (window.location.pathname == "/complete-diamond-ring") {
      history.push("/diamonds");
    } else if (window.location.pathname == "/complete-gemstone-ring") {
      history.push("/gemstones");
    } else if (window.location.pathname == "/complete-fancycolor-ring") {
      history.push("/fancy-color-diamond");
    }
    window.location.reload();
  };
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  });

  return (
    <>
      <div className="rcs_header_seaction" id="rcs_header_seaction">
        {/* <div className='top-heading'>
          <marquee>This is a Demo website for showcase purpose only!</marquee>
        </div> */}
        {topHeader ? (
          <div className="rcs_top_header">
            <Container className="rcs_custom_home_container">
              <div className="rcs_top_head_content">
                <Row className="w-100 m-auto">
                  <Col xs={6} md={9} lg={6} className="p-0">
                    <div className="rcs_top_head_content_right">
                      <ul >
                        <li
                          onClick={(e) => {
                            window.location.href = `${contact_number_link}`;
                          }}
                        >
                          <span>
                            <BsTelephone />
                          </span>{" "}
                          {contact_number.text}{" "}
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col xs={6} md={3} lg={6} className="p-0 text-right">
                    <div className="rcs_top_head_content_vright">
                      <a> <Login /></a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        ) : (
          ""
        )}
        {/* Top Header Section */}
        <div
          // className={
          //   isScrollValueMoreThanHeaderHeight
          //     ? "rcs_logo_head rcs_sticky-top sticky-top"
          //     : "rcs_logo_head"
          // }
          className="rcs_logo_head"
        >
          {/* Header Section */}
          <div className="rcs_header">
            <Container className="p-0">
              <Row className="w-100 m-auto">
                <Col sm={12} md={12} lg={12} xs={12} className="rcs_large_Tab1">
                  {/* Menu Section */}
                  <div className="mg_main_d">
                    <div className="mg_menu_logo_main_div">
                      <Nav className="m-auto rcs_mobile_header_menu">
                        <Image
                          onClick={() => toggleDrawer(true)}
                          src={menuImages}
                          alt="menu icon"
                          width="30px"
                        ></Image>
                      </Nav>
                      <div className="rcs_logo_section px-3">
                        {/* <NavLink to="/"> */}
                        <Image
                          onClick={() => history.push("/")}
                          src={logoImage}
                          alt="Belgium Webnet"
                        ></Image>
                        {/* </NavLink> */}
                      </div>
                      <div className="rcs_right_side rcs_right_side_mobile1 d-lg-none">
                        <ul>
                          {/* <li className="rcs_currency">
                            <Dropdown className="d-inline rcs_dropdown_lang">
                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                <Image
                                  src={dropdown?.img}
                                  alt={dropdown?.code}
                                  style={{ width: "25px" }}
                                ></Image>{" "}
                                <span>{dropdown?.code}</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {currencydata.map((val) => (
                                  <Dropdown.Item
                                    onClick={() => {
                                      currencyHandle(
                                        val.currency_code,
                                        val.country_flag,
                                        val.currency_icon
                                      );
                                    }}
                                    href="#"
                                  >
                                    <Image
                                      src={val.country_flag}
                                      alt={val.currency_code}
                                    ></Image>
                                    <span> {val.currency_code}</span>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li> */}
                          {/* <li>
                            <BiSearch
                              onClick={() => setSearch_show(!search_show)}
                            />
                          </li> */}
                          {/* <li>
                            <Login />
                          </li> */}
                          {/* <li
                            onClick={() =>
                              isLogin
                                ? history.push("/account/wishlist")
                                : history.push("/wishlist")
                            }
                          >
                            <BiHeart className="rcs_cart_mobile_icon1" />{" "}
                            {wishlistlength && wishlistlength != "0" ? (
                              <span className="rcs_wish_badge">
                                {wishlistlength}
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </li> */}
                          <li
                            onClick={() => {
                              history.push("/cart");
                            }}
                          >
                            <MdOutlineShoppingBag className="rcs_cart_mobile_icon" />{" "}
                            {cartlength && cartlength != "0" ? (
                              <span className="rcs_cart_badge">
                                {cartlength}
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="rcs_search_bar">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          history.push("/search/" + search_text);
                          window.scrollTo(0, 0);
                          setSearch_text("");
                          setSearch_show(false);
                        }}
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search for.."
                          value={search_text}
                          onChange={(e) =>
                            setSearch_text(e.target.value)
                          }
                        />
                        <span>
                          <SearchIcon
                            onClick={(e) => {
                              history.push("/search/" + search_text);
                              setSearch_text("");
                              setSearch_show(false);
                            }}
                          />
                        </span>
                      </Form>
                    </div>

                    <div className="mg_menu_right_side_area">
                      <div className="rcs_right_side rcs_right_side_mobile d-none d-lg-block">
                        <ul>
                          {/* <li
                            className="d-none d-lg-block"
                            onClickAway={() => setSearch_show(false)}
                          >
                            <img
                              onClick={() => setSearch_show(!search_show)}
                              onMouseOver={() => setSearchhover(true)}
                              onMouseLeave={() => setSearchhover(false)}
                              className="rcs_wishlist_icon"
                              src={searchhover ? Magnify1 : Magnify0}
                            />
                            <Modal
                              className={
                                isScrollValueMoreThanHeaderHeight
                                  ? "rcs_search_modal12"
                                  : "rcs_search_modal"
                              }
                              show={search_show}
                              onHide={() => setSearch_show(false)}
                            >
                              <div className="rcs_search_bar">
                                <Form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    history.push("/search/" + search_text);
                                    window.scrollTo(0, 0);
                                    setSearch_text("");
                                    setSearch_show(false);
                                  }}
                                >
                                  <SearchIcon
                                    onClick={(e) => {
                                      history.push("/search/" + search_text);
                                      setSearch_text("");
                                      setSearch_show(false);
                                    }}
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="Search for.."
                                    value={search_text}
                                    onChange={(e) =>
                                      setSearch_text(e.target.value)
                                    }
                                  />
                                  <AiOutlineClose
                                    onClick={() => setSearch_show(!search_show)}
                                  />
                                </Form>
                              </div>
                            </Modal>
                          </li> */}
                          {/* <li className="d-none d-lg-block">
                            <Login />
                          </li> */}
                          {/* <li className="d-none d-lg-block">
                            <img
                              onMouseOver={() => setCompare(true)}
                              onMouseLeave={() => setCompare(false)}
                              className="rcs_wishlist_icon"
                              src={!compare ? ComparePng : CompareGif}
                            />
                          </li> */}
                          <li
                            className="d-none d-lg-block"
                            onClick={() =>
                              user?.isLogin
                                ? history.push("/account/wishlist")
                                : history.push("/wishlist")
                            }
                          >
                            <img
                              onMouseOver={() => setWishover(true)}
                              onMouseLeave={() => setWishover(false)}
                              className="rcs_wishlist_icon"
                              src={wishover ? Heart2 : Heart1}
                            />
                            {wishlistlength && wishlistlength != "0" ? (
                              <span className="rcs_wish_badge">
                                {wishlistlength}
                              </span>
                            ) : (
                              ""
                            )}
                          </li>
                          <li
                            className="d-none d-lg-block"
                            onClick={() => {
                              history.push("/cart");
                            }}
                          >
                            <img
                              onMouseOver={() => setCarthover(true)}
                              onMouseLeave={() => setCarthover(false)}
                              className="rcs_wishlist_icon"
                              src={carthover ? Cart2 : Cart1}
                            />
                            {cartlength && cartlength != "0" ? (
                              <span className="rcs_cart_badge">
                                {cartlength}
                              </span>
                            ) : (
                              ""
                            )}
                          </li>
                          <li className="d-none">
                            <Dropdown className="d-inline rcs_dropdown_lang">
                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                <Image
                                  src={currencyflag}
                                  alt={currencycode}
                                ></Image>
                                <span>{currencycode}</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {currencydata?.map((val) => (
                                  <Dropdown.Item
                                    onClick={() => {
                                      currencyHandle(
                                        val.currency_code,
                                        val.country_flag,
                                        val.currency_icon
                                      );
                                    }}
                                    href="#"
                                  >
                                    <Image
                                      src={val.country_flag}
                                      alt={val.currency_code}
                                    ></Image>
                                    <span> {val.currency_code}</span>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>



                  {/* menu Section */}
                </Col>
              </Row>
            </Container>
          </div>
          {/* Header Section */}


        </div>

        <div className="">
          <div className="rcs_menu menu_list_main">
            <Container className="rcs_custome_container1 position-relative">
              <Row>
                <nav id="rcs_menu_header_top">
                  <ul className="rcs_header_menu">
                    {menudata?.map((value, index) => (
                      <li
                        className="rcs_top_header_menu"
                        onMouseEnter={() => {
                          setHover(1);
                          setSubmenu(value);
                        }}
                        onMouseLeave={() => setHover(0)}
                      >
                        <h6 className='1st'>

                          <NavLink to={value?.slug}>
                            {value?.name}
                          </NavLink>
                        </h6>
                        {value?.design == 5 ? <ul
                          className="rcs_top_header_menu_dropdown"
                          onMouseEnter={() => setHover(1)}
                          onMouseLeave={() => setHover(0)}
                        >
                          {submenu?.submenu?.map((val) => (
                            <li className="dir rcs_top_header_menu1">
                              <span>
                                <NavLink to={val?.slug}>
                                  {val.name}
                                </NavLink>
                              </span>
                            </li>
                          ))}
                        </ul> : ""}
                      </li>
                    ))}
                  </ul>
                </nav>
              </Row>

              {/*mega menu */}
              <div className="menu_item_main">
                {submenu?.design != 5 ?
                  <div
                    onMouseEnter={() => setHover(1)}
                    onMouseLeave={() => setHover(0)}
                    className={
                      hover == "1" && submenu?.submenu?.length
                        ? "rcs_top_header_menu_dropdown mega_menu_content_main visible bw-" +
                        submenu?.name?.replaceAll(" ", "-")
                        : "rcs_top_header_menu_dropdown mega_menu_content_main invisible"
                    }
                  >
                    <div className="rcs_megaMenu d-block">
                      <div className="rcs_megaMenu_inner_top">
                        <div className="rcs_megaMenu_inner">
                          <Container fluid className="p-0">
                            <Row className="w-100 m-auto  justify-content-center">
                              {submenu?.submenu?.map((val, index) => (
                                <>
                                  <Col
                                    lg={val?.col}
                                    xl={val?.col}
                                    className="pl-0"
                                  >
                                    {val?.submenu?.map((val1) =>
                                      val1?.design == "1" ? (
                                        <div
                                          className={
                                            "rcs_diamonds_content mj_daimond_d_font bw-" +
                                            val1?.name?.replaceAll(" ", "-")
                                          }
                                        >
                                          <div className="mega-list-cat">
                                            <h2 className="title14 font-bold text-uppercase mtb">
                                              {val1?.name}
                                            </h2>
                                            <ul>
                                              {val1?.submenu?.map(
                                                (val2) => (
                                                  <li>
                                                    {val2?.design == 4 ? (
                                                      <Link
                                                        to="#"
                                                        onClick={() =>
                                                          window.open(
                                                            img_url +
                                                            val2?.slug,
                                                            "_target"
                                                          )
                                                        }
                                                      >
                                                        {val2?.img_url
                                                          ?.length ? (
                                                          <img
                                                            src={
                                                              val2?.img_url
                                                            }
                                                            className="img-size"
                                                            alt={
                                                              val2?.alt_tag
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {val2?.name}
                                                      </Link>
                                                    ) : (
                                                      <Link to={val2?.slug}>
                                                        {val2?.img_url
                                                          ?.length ? (
                                                          <img
                                                            src={
                                                              val2?.img_url
                                                            }
                                                            className="img-size"
                                                            alt={
                                                              val2?.alt_tag
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        {val2?.name}
                                                      </Link>
                                                    )}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      ) : val1?.design == "2" ? (
                                        <div
                                          className={
                                            "rcs_diamonds_content dfL-border_rel bw-" +
                                            val1?.name?.replaceAll(" ", "-")
                                          }
                                        >
                                          <h2> {val1?.name} </h2>
                                          <div className="m-auto w-100 row">
                                            {val1?.submenu?.map((val2) => (
                                              <div className="cust_padd_diamonds_shape col-lg-6">
                                                <div className="rcs_diamonds_content_inner">
                                                  <Link to={val2?.slug}>
                                                    {val2?.img_url
                                                      ?.length ? (
                                                      <img
                                                        src={val2?.img_url}
                                                        alt={val2?.alt_tag}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                    <p>{val2?.name}</p>
                                                  </Link>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      ) : val1?.design == "3" ? (
                                        <div className="rcs_diamonds_content">
                                          <Link to={val1?.slug}>
                                            {val1?.img_url?.length ? (
                                              <img
                                                src={val1?.img_url}
                                                alt={val1?.alt_tag}
                                                className="img-fluid dfl_ddiamond_img"
                                                style={{
                                                  height: "100%",
                                                  objectFit: "cover",
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </Link>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    )}
                                    {index < 2 ? (
                                      <div className="dfl_menu_border">
                                        {" "}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </>
                              ))}
                            </Row>
                          </Container>
                        </div>
                      </div>
                    </div>
                  </div> : ""}

              </div>

              {hover == "1" && submenu?.submenu?.length ? (
                <div className="hover_overlayer"></div>
              ) : (
                ""
              )}
            </Container>
          </div>
        </div>


        {/* Mobile Menu Section */}
        <div className="rcs_menu_mobile d-none">
          <Container>
            <Row>
              <Drawer
                anchor="left"
                open={left}
                onClose={() => toggleDrawer(false)}
              >
                <div role="presentation" onKeyDown={() => toggleDrawer(false)}>
                  <div
                    className={
                      show === true ? "mobile-menu" : "mobile-menu nav-open"
                    }
                  >
                    <div className="inner-mobile-menu">
                      <Accordion defaultActiveKey="0">
                        {/* <Accordion.Item eventKey="0">
                                <Accordion.Header><Link to="#"><span className='auth'><img src={User} alt="" /></span> LOGIN/SIGNUP</Link></Accordion.Header>
                            </Accordion.Item> */}
                        {menudata?.map((val, index) => (
                          <Accordion.Item eventKey={index} className={val.name.replaceAll(" ", "-")}>
                            <Accordion.Header>
                              <Link
                                to={val.slug}
                                onClick={() => toggleDrawer(false)}
                              >
                                {val.name}
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              {val?.submenu?.map((val1) =>
                                val1?.submenu?.map((val2) =>
                                  val2?.design == 3 ? (
                                    <img
                                      className="w-100 mt-2"
                                      onClick={() => history.push(val2?.slug)}
                                      src={val2?.img_url}
                                      alt={val2?.alt_tag}
                                    />
                                  ) : (
                                    <>
                                      <h2 className="title">{val2?.name}</h2>
                                      <ul
                                        className={
                                          val2?.design == 2
                                            ? "two-col-list"
                                            : "single-col-list"
                                        }
                                      >
                                        {val2?.submenu?.map((val3) => (
                                          <li>
                                            <Link
                                              onClick={() => toggleDrawer(false)}
                                              to={val3.slug}
                                            >
                                              {val3?.img_url ? (
                                                <img
                                                  src={val3?.img_url}
                                                  alt={val3?.alt_tag}
                                                />
                                              ) : (
                                                ""
                                              )}{" "}
                                              {val3?.name}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  )
                                )
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                        <Accordion.Item eventKey={8}>
                          <Accordion.Header onClick={() => toggleDrawer(false)}>
                            <Link onClick={() =>
                                  user?.isLogin
                                    ? history.push("/account/wishlist")
                                    : history.push("/wishlist")
                                }> <img className="rcs_wishlist_icon" src={Heart1} /> Wishlist</Link>
                          </Accordion.Header>
                        </Accordion.Item>

                        {/* <Accordion.Item eventKey={9}>
                          <Accordion.Header>
                            <Link onClick={() =>
                              user?.isLogin
                                ? history.push("/diamonds")
                                : history.push("/diamonds")
                            }> <img className="rcs_wishlist_icon" src={ComparePng} /> Compare</Link>
                          </Accordion.Header>
                        </Accordion.Item> */}

                        <Accordion.Item eventKey="7" className="contactDetail">
                          <Accordion.Header>Contact us</Accordion.Header>
                          <div className="contact_detail">
                            <ul>
                              {/* <li>
                                <a
                                  onClick={() => toggleDrawer(false)}
                                  target="_blank"
                                  href="https://api.whatsapp.com/send?phone=+6469929024 &amp;text=Hi Arman Jwelers ! I have a few queries. Could you please help me out?"
                                >
                                  <span>
                                    <BsFillChatFill />
                                  </span>{" "}
                                  Chat
                                </a>
                              </li> */}
                              <li onClick={() => toggleDrawer(false)}>
                                <a onClick={(e) => {
                                  window.location.href = `${contact_number_link}`;
                                }}>
                                  <span>
                                    <FaPhone />
                                  </span>{" "}
                                  Call
                                </a>
                              </li>
                              <li onClick={() => toggleDrawer(false)}>
                                <a onClick={(e) => {
                                  window.location.href = `${Email_details_link}`;
                                }}>
                                  <span>
                                    <FaEnvelope />
                                  </span>{" "}
                                  Email
                                </a>
                              </li>
                              <li onClick={() => toggleDrawer(false)}>
                                <Link
                                  to="/contact"
                                >
                                  <span>
                                    <FaUser />
                                  </span>{" "}
                                  Contact
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </Drawer>
            </Row>
          </Container>
        </div>
        {/* Mobile Menu Section */}
      </div>




    </>
  );
};
export default MegaMenu;
