import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "../Reducer/cartReducer";
import wishlistReducer from "../Reducer/wishlistReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import metaReducer from "../Reducer/metaReducer";
import homeReducer from "../Reducer/homeReducer";
import bandReducer from "../Reducer/bandReducer";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    cart: cartReducer,
    wish: wishlistReducer,
    meta: metaReducer,
    home: homeReducer,
    bandBuilder: bandReducer,
  })
);

const store = configureStore({
  reducer: {
    persistedReducer,
  },
});
const persistor = persistStore(store);
export { store, persistor };
