import React from 'react'
import { Container } from 'react-bootstrap'
import StylishBorder from "./../../../../Assets/images/New York/Slider/line-black.png";
const About = () => {
    return (
        <div className='homeAbout section-space pt-0'>
            <Container>
                <div className='title-box text-center mb-4'>
                    <h2 className='text-center'>About Morgan Taylor Jewelers</h2>
                    <img src={StylishBorder} alt="" />
                </div>
                <p className='text-center'><b>Morgan Taylor Jewelers</b>, located on Sahara Ave and Buffalo Dr in Las Vegas, Nevada, offers an exceptional selection of fine jewelry, loose diamonds, and custom designs tailored to the most discerning tastes. With over <b>35 years</b> of serving the local community, we have earned a reputation for delivering unparalleled craftsmanship, outstanding service, and competitive pricing that exceeds expectations.<br /><br />   Our services include expert jewelry and watch repairs, appraisals, as well as complimentary inspections and cleanings to ensure your pieces remain as stunning as the day you received them. <br /><br />  Visit us today to explore our exquisite collection and find the perfect diamond set in a design that reflects your unique style.</p>
            </Container>
        </div>
    )
}

export default About
