import React from 'react'
import ReactOwlCarousel from 'react-owl-carousel';
import slider1 from './../../../../Assets/images/New York/Slider/slider-1.png';
import slider2 from './../../../../Assets/images/New York/Slider/slider-2.png';
import slider3 from './../../../../Assets/images/New York/Slider/slider-1.jpg';
import slidermob3 from './../../../../Assets/images/New York/Slider/slider-mob-1.jpg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import {BsArrowRight} from 'react-icons/bs';
import { isMobile, isMobileOnly } from 'react-device-detect';


const BannerSlider = () => {
    const [sliders, setSliders] = useState([
        {img: slider3, mobimg: slidermob3, title: 'Engagement Rings', content: 'Discover our unique Engagement Rings collection', link: '/jewelry/engagement-rings', btnName: 'Shop Engagement Rings', class: 'right-50'},
        // {img: slider2, title: 'Perfect Symbol of Love', content: 'Explore the latest Wedding collection', link: '/jewelry/wedding-rings', btnName: 'Shop Wedding Rings'},
        // {img: slider3, title: 'Fine Jewelry', content: 'Explore the latest Fine Jewelry', link: '/jewelry/fine-jewelry', btnName: 'Shop Fine Jewelry'}
    ]);
    


    return (
        <div className='banner-slider'>
            <ReactOwlCarousel navText={["", '<BsArrowRight/>']} className="owl-main owl-theme" nav responsive={
                {
                    0: {
                        nav: false,
                    },

                    768: {
                        nav: true
                    }
                }
            } items={1} loop>
                {
                    sliders.map((slide, i) => <div key={i} class='item'>
                        {isMobileOnly ? <img src={slide.mobimg} alt='' /> : <img src={slide.img} alt='' />}
                        

                        <div className={'content ' + slide.class}> 
                            <h2>{slide.title}</h2>
                            <p className='m-0'>{slide.content}</p>
                            <Link className='btn solid-black' to={slide.link}>{slide.btnName}</Link>
                        </div>
                    </div>)
                } 
            </ReactOwlCarousel>    

            
        </div>
    )
}

export default BannerSlider;