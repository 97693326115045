import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
  Form,
  Modal,
} from "react-bootstrap";
import { base_url, postHeader } from "../../../../Helpers/request";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../../../../Components/Loader";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { client_name } from '../../../../Helpers/request';

export const Newsletter = () => {
  const history = useHistory();
  const currencycode = useSelector(
    (state) => state.persistedReducer.home.currencycode
  );
  const [newsletteremail, setNewsletteremail] = useState("");
  const [loader, setLoader] = useState(false);
  const newsletter = (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      currency_code: currencycode,
      email: newsletteremail,
    };
    axios
      .post(base_url + "/common/subscribe_newsletter", data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          setLoader(false);
          setNewsletteremail("");
          return swal(res.data.message, "", "success");
        } else {
          setLoader(false);
          return swal(res.data.message, "", "error");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  return (
    <>
      <section className='signup_section'>
        <div className='container text-center'>
          <h3 className='mb-3'>SIGN UP TO BE A {client_name.c_name} INSIDER</h3>
          <p className='mb-3'>Become a preferred subscriber and get a special offer on your purchase.</p>
          <div className='form'>
            <form onSubmit={newsletter}>
              <InputGroup className='justify-content-center'>
                <Form.Control
                  placeholder="EMAIL ADDRESS"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  type="email"
                  value={newsletteremail}
                  required
                  onChange={(e) => setNewsletteremail(e.target.value)}
                />
                <Button variant="outline-secondary" type="submit"
                  id="button-addon2">SUBSCRIBE</Button>
              </InputGroup>
            </form>
          </div>
          <p className='desc-dark'>By signing up you confirm that you have read the <Link to="#" className='text-dark'>Privacy Policy</Link> and agree that your email will be collected and used for the purposes of sending news, promotions and updates via email. You can withdraw your consent at any time by unsubscribing.</p>
        </div>
        <Modal show={loader}>
          <Loader />
        </Modal>
      </section>
    </>
  )
};
