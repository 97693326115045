import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import StylishBorder from './../../../../Assets/images/New York/Slider/line-black.png';
import Ring1 from './../../../../Assets/images/New York/Slider/rings-v1.png';
import Neck1 from './../../../../Assets/images/New York/Slider/nackv1.png';
import WeddingRings from './../../../../Assets/images/New York/Slider/ringv1.png';
import EarRings from './../../../../Assets/images/New York/Slider/earingv1.png';
import Brac from './../../../../Assets/images/New York/Slider/bracv1.png';

const OurCollection = () => {
  return (
    <>
        <section className='our_collection'>
            <div className='container'>
                <div className='title-box text-center mb-5'>
                    <h2>Explore Our Collection</h2>
                    <img src={StylishBorder} alt='' />
                </div>

                <div className='collections'>
                    <div className="banner-block block-1 wow slideInRight">
                        <div className="banner-box">
                            <Link to="jewelry/engagement-rings" className='d-block position-relative'>
                                <div className="s-desc">
                                    <h3>Engagement <br/> Rings</h3>
                                    <p className=''>Select your perfect Engagement Ring with diamond. Choose from wide range of designer rings.</p>
                                </div>
                                <img src={Ring1} alt="Necklaces" className="img-responsive w-100" />
                            </Link>
                        </div>
                    </div>

                    <div className="banner-block block-2 wow slideInRight">
                        <div className="banner-box">
                            <Link to="jewelry/necklaces" className='d-block position-relative'>
                                <div className="s-desc">
                                    <h3>Necklaces</h3>
                                    <p className='p-0'>Find the perfect length for every occasion with classic pearl strands, diamond elegance, colorful gemstones and more!</p>
                                </div>
                                <img src={Neck1} alt="Necklaces" className="img-responsive w-100" />
                            </Link>
                        </div>
                    </div>

                    <div className="banner-block block-3 wow slideInLeft">
                        <div className="banner-box">
                            <Link to="jewelry/wedding-rings" className="d-block position-relative">
                                <div className="s-desc">
                                    <h3>Wedding Rings</h3>
                                    <p className='p-0'>Find the perfect symbol of your love. Discover eternity diamond rings, classic bands, and unique styles.</p>
                                </div>
                                <img src={WeddingRings} alt="Wedding Rings" className="img-responsive w-100" />
                            </Link>
                        </div>
                    </div>

                    <div className="banner-block block-4 wow slideInLeft">
                        <div className="banner-box">
                            <Link to="jewelry/earrings" className="d-block position-relative">
                                <div className="s-desc">
                                    <h3>Earrings</h3>
                                    <p>Look your best every day! </p>
                                    <p>Explore our exciting varieties of stylish earrings from short and sweet to long and dramatic.</p>
                                </div>
                                <img src={EarRings} alt="Earrings" className="img-responsive w-100" />
                            </Link>
                        </div>
                    </div>

                    <div className="banner-block block-5 wow slideInRight">
                        <div className="banner-box">
                            <Link to="jewelry/bracelets" className="d-block position-relative">
                                <div className="s-desc">
                                    <h3>Bracelets</h3>
                                    <p>Adorn your wrist with perfection and complement every style with feminine fashion, bangle, and diamond bracelets.</p>
                                </div>
                                <img src={Brac} alt="Bracelets" className="img-responsive w-100" />
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}

export default OurCollection;