import { RiFacebookBoxFill, RiTwitterFill, RiInstagramLine, RiLinkedinBoxFill } from "react-icons/ri";
import { FaYelp } from "react-icons/fa";
import { Navigate, Outlet } from "react-router";
import Social1 from '../Assets/images/static/Social/1.png';
import Social2 from '../Assets/images/static/Social/2.png';
import Social3 from '../Assets/images/static/Social/3.png';
import Social7 from '../Assets/images/static/Social/7.png';
import Social5 from '../Assets/images/static/Social/5.png';
import { useSelector } from "react-redux";
//-------------------------------------------------url
export const base_url = "https://morgantaylorjewelers.net/webapi/api";
export const img_url = "https://morgantaylorjewelers.net";
export const helmet_url = "https://morgantaylorjewelers.net";
export const is_paypal = true;
export const is_square = true;
export const Je_welryspelling = "jewelry";
// ------Contact Details Start ------
export const contact_number = {                // <===---contact Number //
  text: '+1 (702) 259-8011',
  link: `tel:+17022598011`
};

export const Email_details = {                      // <===---Email_Details  //
  text: 'info@morgantaylorjewelers.net',
  link: `mailto:info@morgantaylorjewelers.net`
};

export const Email_detailsSecond = {                // <===---Second_Email_details  // 
  text: '',
  link: `mailto:`
};

export const Address_details = {                     // <===---Address_details  // 
  textLine1: '7995 w Sahara ave #103,',
  textLine2: 'Las Vegas, NV 89117, United States',
  link: "https://www.google.com/maps/place/Morgan+Taylor+Jewelers/@36.1431922,-115.2675514,17z/data=!4m15!1m8!3m7!1s0x80c8c095f63c6765:0x936355507c991235!2s7995+W+Sahara+Ave+%23103,+Las+Vegas,+NV+89117,+USA!3b1!8m2!3d36.1431879!4d-115.2649765!16s%2Fg%2F11mhjwg647!3m5!1s0x80c8c0be22e29d4b:0xb309d2780c00d0ee!8m2!3d36.1431879!4d-115.2649765!16s%2Fg%2F1tdxfkjv?entry=ttu"
};

export const client_name = {                          // <===---client_name  // 
  c_name: 'Morgan Taylor Jewelers',
};

export const media_details = [                           // <===---media_details  // 
  {
    account_alt: 'Facbook',
    account_icon: <RiFacebookBoxFill />,
    account_img: Social1,
    account_link: "https://www.facebook.com/morgantaylorjewelers/"
  },
  // {
  //   account_alt: 'Twitter',
  //   account_icon: <RiTwitterFill />,
  //   account_img: Social7,
  //   account_link: ""
  // },
  {
    account_alt: 'Instgram',
    account_icon: <RiInstagramLine />,
    account_img: Social2,
    account_link: "https://www.instagram.com/morgantaylorjewelers/"
  },
  // {
  //   account_alt: 'Linkedin',
  //   account_icon: <RiLinkedinBoxFill />,
  //   account_img: Social3,
  //   account_link: ""
  // },
  {
    account_alt: 'Yelp',
    account_icon: <FaYelp />,
    account_img: Social5,
    account_link: "https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas"
  },
];


export const InvoiceAddress = () => {              // <===---Invoice_Address  //
  return (
    <>
      <h4 className="mb-2">7995 W Sahara Ave #103,</h4>
      <h4 className="mb-0"> Las Vegas, 83117, United States</h4>
    </>
  )
};

//--------------------------------------------------Api header data
export const postHeader = {
  // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export const onTop = () => {
  window.scrollTo(0, 0);
}

export const ProtectedRoutes = () => {
   const user = useSelector(
    (state) => state.persistedReducer.home.userData
  );
  return user?.isLogin ? <Outlet /> : <Navigate to="/" />
}
//---------------------------------------------------Ring builder
export const setSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem('bw-diamonddata')) ? JSON.parse(localStorage.getItem('bw-diamonddata')) : null;
  const settingdata = JSON.parse(localStorage.getItem('bw-settingdata')) ? JSON.parse(localStorage.getItem('bw-settingdata')) : null;
  const gemstonedata = JSON.parse(localStorage.getItem('bw-gemstonedata')) ? JSON.parse(localStorage.getItem('bw-gemstonedata')) : null;
  const fancycolordata = JSON.parse(localStorage.getItem('bw-fancycolordata')) ? JSON.parse(localStorage.getItem('bw-fancycolordata')) : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  }
}

//---------------------------------------------------stud builder
export const setStudSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem('bw-stud-diamonddata')) ? JSON.parse(localStorage.getItem('bw-stud-diamonddata')) : null;
  const settingdata = JSON.parse(localStorage.getItem('bw-stud-settingdata')) ? JSON.parse(localStorage.getItem('bw-stud-settingdata')) : null;
  const gemstonedata = JSON.parse(localStorage.getItem('bw-stud-gemstonedata')) ? JSON.parse(localStorage.getItem('bw-stud-gemstonedata')) : null;
  const fancycolordata = JSON.parse(localStorage.getItem('bw-stud-fancycolordata')) ? JSON.parse(localStorage.getItem('bw-stud-fancycolordata')) : null;
  var arr;
  if (check == 0) {
    if (settingdata == null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 1) {
    if (settingdata == null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 2) {
    if (settingdata == null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (settingdata != null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 3) {
    if (diamonddata == null) {
      arr = [3, 1];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (diamonddata != null) {
      arr = [1, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
      arr = [3, 2];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (gemstonedata != null) {
      arr = [2, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
      arr = [3, 0];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    } else if (fancycolordata != null) {
      arr = [0, 3];
      sessionStorage.setItem("bw-stud-step", JSON.stringify(arr))
    }
  }
}


//------------------- Image error------------------------

export const ErrorImg = (dia_shape, id, type) => {
  if (dia_shape == "Round") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/round.jpg";
  } else if (dia_shape == "Princess") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/princess.jpg";
  } else if (dia_shape == "Cushion") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/cushion.jpg";
  } else if (dia_shape == "Emerald") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/emerald.jpg";
  } else if (dia_shape == "Oval") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/oval.jpg";
  } else if (dia_shape == "Radiant") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/radiant.jpg";
  } else if (dia_shape == "Asscher") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/asscher.jpg";
  } else if (dia_shape == "Marquise") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/marquise.jpg";
  } else if (dia_shape == "Heart") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/heart.jpg";
  } else if (dia_shape == "Pear") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/pear.jpg";
  } else if (dia_shape == "Gemstone") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/gemstone.png";
  } else if (dia_shape == "Fancy") {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/fancy_diamond.png";
  } else if (dia_shape === "none"){
    var img = "https://dl2vs6wk2ewna.cloudfront.net/image-not-found.png";
  } else {
    var img = "https://dl2vs6wk2ewna.cloudfront.net/shapes/diamond.png";
  }

  
  if (type == "detail" || type == "Complete") {
    if (type == "Complete") {
      var imgsrc = document.getElementsByClassName("image-gallery-thumbnail-image")[1].getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName("image-gallery-thumbnail-image")[1].src = img;
      }
    } else {
      var imgsrc = document.getElementsByClassName("image-gallery-thumbnail")[0].getElementsByTagName("img")[0].getAttribute("src");
      var image = new Image();
      image.src = imgsrc;
      image.onerror = () => {
        document.getElementsByClassName("image-gallery-thumbnail-image")[type == "Complete" ? 1 : 0].src = img;
        document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = img;
      }
      image.onload = () => {
        document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = imgsrc;
      }
    }
    // image.onload = () => {
    //   document.getElementsByClassName("image-to-magnify")[0].getElementsByTagName("img")[0].src = imgsrc;
    // }
  } else {
    console.log("ImageError sku: ", id)
    document.getElementById(id).src = img;
  }
}