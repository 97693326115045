import React from "react";
import StylishBorder from "./../../../../Assets/images/New York/Slider/line-black.png";
import Star from "./../../../../Assets/images/New York/Slider/str.svg";
import GoogleReview from "./../../../../Assets/images/New York/Slider/google-reviews.jpg";
import YelpReview from "./../../../../Assets/images/New York/Slider/yelp-reviews.jpg";
import IvouchReview from "./../../../../Assets/images/New York/ivouch-reviews.jpg";
import ReactOwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { ArrowBackIos, ArrowRightAlt } from "@material-ui/icons";
import { FaQuoteLeft } from "react-icons/fa";
import { Tab, Tabs } from "react-bootstrap";
import { Rating } from "@mui/material";

const WhatPeopleSay = () => {
  return (
    <>
      <section className="testimonials">
        <div className="container">
          <div className="title-box text-center pb-4">
            <h2>WHAT PEOPLE SAY</h2>
            <img src={StylishBorder} alt="" />
          </div>

          <div className="slider-wrapper">
            <Tabs
              defaultActiveKey="google"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="google" title="">
                <div className="google-review review-block">
                  <div className="header text-center">
                    <a  href="https://www.google.com/search?q=morgan+taylor+jewelers&rlz=1C1UEAD_en-GBIN1070IN1070&oq=Morgan+Taylor+Jewelers&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBwgCEAAYgAQyCggDEAAYgAQYogQyCggEEAAYgAQYogQyBggFEEUYPDIGCAYQRRg8MgYIBxBFGD3SAQczMDdqMGo0qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x80c8c0be22e29d4b:0xb309d2780c00d0ee,3,,,," target="_blank" rel="noreferrer"><img src={GoogleReview} alt="" /></a>
                  </div>
                  <div className="body text-center position-relative">
                    <div className="testimonial-icon">
                      <FaQuoteLeft />
                    </div>
                    <ReactOwlCarousel
                      className="owl-main owl-theme"
                      nav={true}
                      dots={true}
                      navText={[ArrowBackIos, ArrowRightAlt]}
                      items={1}
                      loop
                    >
                      <div className="item">
                        <p>
                          <span>
                            I had an excellent experience at Morgan Taylor jewelry shopping for my girlfriends engagement ring. It was a first time experience buying a ring and Raul walked me through the entire journey. He went above and beyond in finding the ring that fit my requested preference.
                          </span>
                          <a
                            target="_blank" rel="noreferrer"
                            href="https://www.google.com/search?q=morgan+taylor+jewelers&rlz=1C1UEAD_en-GBIN1070IN1070&oq=Morgan+Taylor+Jewelers&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBwgCEAAYgAQyCggDEAAYgAQYogQyCggEEAAYgAQYogQyBggFEEUYPDIGCAYQRRg8MgYIBxBFGD3SAQczMDdqMGo0qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x80c8c0be22e29d4b:0xb309d2780c00d0ee,1,,,,"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1.1" defaultValue={5} precision={1.1} readOnly />
                        </div>
                        <h3>
                          
                            Ricky Chen
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                            They did an awesome job fixing my ring. They took my ring size up three sizes and got It done quickly.
                            Three weeks prior They also upgraded my wife’s ring for our 25th anniversary. Dan and the crew did a great job getting the job done Secretly,
                          </span>
                          <a
                            target="_blank" rel="noreferrer"
                            href="https://www.google.com/search?q=morgan+taylor+jewelers&rlz=1C1UEAD_en-GBIN1070IN1070&oq=Morgan+Taylor+Jewelers&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBwgCEAAYgAQyCggDEAAYgAQYogQyCggEEAAYgAQYogQyBggFEEUYPDIGCAYQRRg8MgYIBxBFGD3SAQczMDdqMGo0qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x80c8c0be22e29d4b:0xb309d2780c00d0ee,1,,,,"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="5" defaultValue={5} precision={5} readOnly />
                        </div>
                        <h3>
                          
                            Rudy The MAN
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                            My engagement ring and wedding band are from Morgan Taylor and I’ve had so many positive experiences with them in the past. However, the best experience was when I came in with a request for three matching custom necklaces.
                          </span>
                          <a
                            target="_blank" rel="noreferrer"
                            href="https://www.google.com/search?q=morgan+taylor+jewelers&rlz=1C1UEAD_en-GBIN1070IN1070&oq=Morgan+Taylor+Jewelers&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBwgCEAAYgAQyCggDEAAYgAQYogQyCggEEAAYgAQYogQyBggFEEUYPDIGCAYQRRg8MgYIBxBFGD3SAQczMDdqMGo0qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x80c8c0be22e29d4b:0xb309d2780c00d0ee,1,,,,"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1.3" defaultValue={5} precision={1.3} readOnly />
                        </div>
                        <h3>
                          
                            Ashley Colson
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                            It is an absolute pleasure to write a review for this business. I wish there was six stars I could leave!

                            Tom and Rahul, the owners were absolutely outstanding! They both really care about the client experience. They are incredibly trustworthy, honest, full of integrity and so incredibly generous.
                          </span>
                          <a
                            target="_blank" rel="noreferrer"
                            href="https://www.google.com/search?q=morgan+taylor+jewelers&rlz=1C1UEAD_en-GBIN1070IN1070&oq=Morgan+Taylor+Jewelers&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyBwgCEAAYgAQyCggDEAAYgAQYogQyCggEEAAYgAQYogQyBggFEEUYPDIGCAYQRRg8MgYIBxBFGD3SAQczMDdqMGo0qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x80c8c0be22e29d4b:0xb309d2780c00d0ee,1,,,,"
                          >
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1.4" defaultValue={5} precision={1.4} readOnly />
                        </div>
                        <h3>
                          
                            Tanya Pluckrose
                        </h3>
                      </div>
                    </ReactOwlCarousel>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="yelp" title="">
                <div className="yelp-review review-block">
                  <div className="header text-center">
                    <img src={YelpReview} alt="" />
                  </div>
                  <div className="body text-center position-relative">
                    <div className="testimonial-icon">
                      <FaQuoteLeft />
                    </div>
                    <ReactOwlCarousel
                      className="owl-main owl-theme"
                      navText={[ArrowBackIos, ArrowRightAlt]}
                      items={1}
                      loop
                      responsive={{
                        0: {
                          nav: false,
                        },

                        768: {
                          nav: true,
                        },
                      }}
                    >
                      <div className="item">
                        <p>
                          <span>
                          Wow just wow, the service I received when I recently brought in my ring to be resized was just amazing. They took such amazing care of an item so special to me and had such a quick turn around. Truly best in town. Thank you so much
                          </span>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1" defaultValue={5} precision={5} readOnly />
                        </div>
                        <h3>
                          
                          Jessica B.
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                          The wonderful jewelers at Morgan Taylor exceeded our expectations once again! My daughter is in the fashion industry in New York City and only trusts her jewelry repairs, alterations and design to Morgan Taylor here in Vegas. They always go above and beyond in meeting our needs. They are all true professionals and a pleasure to work with.
                          </span>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="2" defaultValue={5} precision={2} readOnly />
                        </div>
                        <h3>
                          
                          Cathy N.
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                          I've been going to Morgan Taylor for years to have minor watch repairs done and I was always impressed with their friendly and honest service, but It wasn't until recently that I made a major purchase, a Wedding Ring for my sweetheart of over 40 years! Better late than never, right? The store manager Raul was amazing and we designed a beautiful piece that absolutely hit the spot with my sweetheart. She can't take her eyes off the ring, and proudly wears it every day! These folks are very personable and it's so much more comforting dealing with a small trustworthy operation than with a large department store.
                          </span>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="3" defaultValue={5} precision={3} readOnly />
                        </div>
                        <h3>
                          Rick H.
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                          I can't say enough about this Jeweler! Above and beyond any other jewelry store I went to. It took a while for me to find a place I liked to my my now wife's engagement ring. She wanted a sapphire in the center, so I got it custom-made. Raul was great the couple times I was in there looking before I decided to go with them.
                          </span>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="4" defaultValue={5} precision={4} readOnly />
                        </div>
                        <h3>
                          Adam K.
                        </h3>
                      </div>

                      <div className="item">
                        <p>
                          <span>
                          Great service, nice selection at very reasonable prices. I have been a customer for many years and I also rely on Morgan Taylor for maintenance and repair of my existing pieces.
                          </span>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="5" defaultValue={5} precision={5} readOnly />
                        </div>
                        <h3>
                          Jeffery B.
                        </h3>
                      </div>
                    </ReactOwlCarousel>
                  </div>
                </div>
              </Tab>
              {/* <Tab eventKey="ivouch" title="">
                <div className="ivouch-review review-block">
                  <div className="header text-center">
                    <img src={IvouchReview} alt="" />
                  </div>
                  <div className="body text-center position-relative">
                    <div className="testimonial-icon">
                      <FaQuoteLeft />
                    </div>
                    <ReactOwlCarousel
                      className="owl-main owl-theme"
                      navText={[ArrowBackIos, ArrowRightAlt]}
                      items={1}
                      loop
                      responsive={{
                        0: {
                          nav: false,
                        },

                        768: {
                          nav: true,
                        },
                      }}
                    >
                      <div className="item">
                        <p>
                          <span>Very satisfied with my experience with lake hillier, i bought my girlfriend 2 earrings for Bday  and she loved it! High quality products and the service was exceptional</span>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1" defaultValue={5} precision={5} readOnly />
                        </div>
                        <h3>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">
                            Harris Hodges.
                          </a>
                        </h3>
                      </div>
                      <div className="item">
                        <p>
                          <span>Very satisfied with my experience with lake hillier, i bought my girlfriend 2 earrings for Bday  and she loved it! High quality products and the service was exceptional</span>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1" defaultValue={5} precision={5} readOnly />
                        </div>
                        <h3>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">Harris Hodges.</a>
                        </h3>
                      </div>
                      <div className="item">
                        <p>
                          <span>
                            Very satisfied with my experience with lake hillier, i bought my girlfriend 2 earrings for Bday  and she loved it! High quality products and the service was exceptional
                          </span>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">
                            Read More
                          </a>
                        </p>
                        <div className="aj_rev_RatingStar">
                          <Rating name="1" defaultValue={5} precision={5} readOnly />
                        </div>
                        <h3>
                          <a target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/morgan-taylor-jewelers-las-vegas">
                            Harris Hodges.
                          </a>
                        </h3>
                      </div>
                    </ReactOwlCarousel>
                  </div>
                </div>
              </Tab> */}
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatPeopleSay;
